exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-amenities-cn-js": () => import("./../../../src/pages/amenities.cn.js" /* webpackChunkName: "component---src-pages-amenities-cn-js" */),
  "component---src-pages-amenities-js": () => import("./../../../src/pages/amenities.js" /* webpackChunkName: "component---src-pages-amenities-js" */),
  "component---src-pages-commercial-cn-js": () => import("./../../../src/pages/commercial.cn.js" /* webpackChunkName: "component---src-pages-commercial-cn-js" */),
  "component---src-pages-commercial-js": () => import("./../../../src/pages/commercial.js" /* webpackChunkName: "component---src-pages-commercial-js" */),
  "component---src-pages-contact-cn-js": () => import("./../../../src/pages/contact.cn.js" /* webpackChunkName: "component---src-pages-contact-cn-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contactcn-cn-js": () => import("./../../../src/pages/contactcn.cn.js" /* webpackChunkName: "component---src-pages-contactcn-cn-js" */),
  "component---src-pages-contactcn-js": () => import("./../../../src/pages/contactcn.js" /* webpackChunkName: "component---src-pages-contactcn-js" */),
  "component---src-pages-contacthk-hk-js": () => import("./../../../src/pages/contacthk.hk.js" /* webpackChunkName: "component---src-pages-contacthk-hk-js" */),
  "component---src-pages-contacthk-js": () => import("./../../../src/pages/contacthk.js" /* webpackChunkName: "component---src-pages-contacthk-js" */),
  "component---src-pages-floorplans-cn-js": () => import("./../../../src/pages/floorplans.cn.js" /* webpackChunkName: "component---src-pages-floorplans-cn-js" */),
  "component---src-pages-floorplans-js": () => import("./../../../src/pages/floorplans.js" /* webpackChunkName: "component---src-pages-floorplans-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-cn-js": () => import("./../../../src/pages/index.cn.js" /* webpackChunkName: "component---src-pages-index-cn-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interiors-cn-js": () => import("./../../../src/pages/interiors.cn.js" /* webpackChunkName: "component---src-pages-interiors-cn-js" */),
  "component---src-pages-interiors-js": () => import("./../../../src/pages/interiors.js" /* webpackChunkName: "component---src-pages-interiors-js" */),
  "component---src-pages-register-index-cn-js": () => import("./../../../src/pages/register/index.cn.js" /* webpackChunkName: "component---src-pages-register-index-cn-js" */),
  "component---src-pages-register-index-js": () => import("./../../../src/pages/register/index.js" /* webpackChunkName: "component---src-pages-register-index-js" */),
  "component---src-pages-register-residential-cn-js": () => import("./../../../src/pages/register/residential.cn.js" /* webpackChunkName: "component---src-pages-register-residential-cn-js" */),
  "component---src-pages-register-residential-js": () => import("./../../../src/pages/register/residential.js" /* webpackChunkName: "component---src-pages-register-residential-js" */),
  "component---src-pages-register-strata-office-cn-js": () => import("./../../../src/pages/register/strata-office.cn.js" /* webpackChunkName: "component---src-pages-register-strata-office-cn-js" */),
  "component---src-pages-register-strata-office-js": () => import("./../../../src/pages/register/strata-office.js" /* webpackChunkName: "component---src-pages-register-strata-office-js" */),
  "component---src-pages-register-walk-in-cn-js": () => import("./../../../src/pages/register-walk-in.cn.js" /* webpackChunkName: "component---src-pages-register-walk-in-cn-js" */),
  "component---src-pages-register-walk-in-js": () => import("./../../../src/pages/register-walk-in.js" /* webpackChunkName: "component---src-pages-register-walk-in-js" */)
}

